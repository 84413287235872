import React from "react"
import { Helmet } from "react-helmet"
import "./events.css"
import "../components/partner.css"
import 'bootstrap/dist/css/bootstrap.min.css'

import WildlifeLogo from "../images/logos/wildlife-logo.png"
import EmmaHead from "../images/gatsby-icon.png"
import event_img from '../images/events-img/1-python-folder/background1.jpg'
import MyFooter from "../components/footer.js"
import MyNavbar from "../components/navbar.js"
import EventBanner from "../components/event-banner.js"


const EventsPage = () => (
  <div className="index">
    <Helmet>
      <title>1° Curso Python</title>
      <link rel="icon" href={EmmaHead} />
    </Helmet>
      <MyNavbar />
      <EventBanner 
        mobile_img = {event_img}
        img = {event_img}
        name = "1o Curso Introdutório de Programação em Python (TFG/Wildlife Studios)"
        date = "Agosto/2019"
        description = "As aulas são baseadas em conteúdo expositivo (através de slides e cadernos de exercícios) e interativo (no formato de quiz através do site Kahoot). As alunas são desafiadas a realizar exercícios durante o curso e a desenvolver um EP (exercício-programa) como projeto final de conclusão do curso. Realizado em parceria com a TFG (atual Wildlife Studios) e apoio do ISA CTEEP, Centro Acadêmico de Engenharia Ambiental da Escola Politécnica (CAEA/Poli-USP) e Centro Acadêmico de Engenharia de Produção da Escola Politécnica (CAEP/Poli-USP), o primeiro oferecimento do curso teve carga horária de 16 horas e atingiu 33 meninas das escolas ETEC Caieiras e E.E. Dr. Mario Toledo Moraes dos 2o e 3o anos do Ensino Médio." 
      />
      <div className="partner-container">
        <div className="partner-title">PARCERIAS</div>
        <div className="partner-flex">
            <div className="partner-element">
                <img src={WildlifeLogo} alt="" className="partner-logo"/>
                <p className="partner-name">Wildlife Studios</p>
            </div>
        </div>
      </div>
      <MyFooter />
    </div>
)

export default EventsPage
