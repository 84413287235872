import React from "react"
import Logo from "../images/logo.png"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'

import "./footer.css"

const MyFooter = () => (

        <div className="my-footer" id="footer">
            <img src={ Logo } style={{height: "70px", marginBottom: "10px"}} alt="logo"/>
            <div className="footer-legend">Entre em contato pelas nossas redes sociais</div>
            <div className="social-icons">
                {/*
                <Link href="#">
                    <div className="social-media-circle whats-circle">
                        <FontAwesomeIcon className="social-media-icon whats-icon" icon={ faWhatsapp } />
                    </div>
                </Link>
                */}
                <a href="https://www.youtube.com/channel/UCwiX71tH7SdAjsqwIibkPUw" target="_blank" rel="noopener noreferrer">
                    <div className="social-media-circle face-circle">
                        <FontAwesomeIcon className="social-media-icon face-icon" icon={ faYoutube } />
                    </div>
                </a>
                <a href="https://www.facebook.com/elaspelasexatas/" target="_blank" rel="noopener noreferrer">
                    <div className="social-media-circle face-circle">
                        <FontAwesomeIcon className="social-media-icon face-icon" icon={ faFacebook } />
                    </div>
                </a>
                <a href="https://www.instagram.com/elaspelasexatas/" target="_blank" rel="noopener noreferrer">
                    <div className="social-media-circle insta-circle">
                        <FontAwesomeIcon className="social-media-icon insta-icon" icon={ faInstagram } />
                    </div>
                </a>
                <a href="https://www.linkedin.com/company/elas-pela-engenharia/?originalSubdomain=do" target="_blank" rel="noopener noreferrer">
                    <div className="social-media-circle linkedin-circle">
                        <FontAwesomeIcon className="social-media-icon linkedin-icon" icon={ faLinkedin } />
                    </div>
                </a>
                <a href="mailto:contato@elaspelasexatas.com" target="_blank" rel="noopener noreferrer">
                    <div className="social-media-circle mail-circle">
                        <FontAwesomeIcon className="social-media-icon mail-icon" icon={ faEnvelope } />
                    </div>
                </a>
            </div>
            <div className="footer-links">
                <a href="/" className="footer-link">Home</a>
                <a href="/#about" className="footer-link">Sobre Nós</a>
                <a href="/#events" className="footer-link">Projetos</a>
                <a href="/#members" className="footer-link">Integrantes</a>
            </div>
        </div>
)

export default MyFooter
