import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'

import "./event-banner.css"
 

const EventBanner = (props) =>(
    <div className="event-banner-container"> 
        <div className="my-event-img-container-mobile">
            <img className="my-event-img-mobile" src={props.mobile_img} alt="event_img_mobile"/>
        </div>
        <div className="event-banner-flex">
            <div className="my-event-img-container">
                <img className="my-event-img" src={props.img} alt="event_img"/>
            </div>
            <div className="my-event-text">
                <div className="my-event-name">{props.name}</div>
                <div className="my-event-date">
                    <FontAwesomeIcon style={{marginTop:"5px"}}className="event-icon" icon={faCalendar} />
                <div className="date-number">{props.date}</div>
                </div>
                <div className="my-event-description">
                    {props.description}
                </div>
            </div>
        </div>
    </div>
) 
 
export default EventBanner;