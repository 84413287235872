import React from "react"
import Logo from "../images/logo.png"
import { Navbar, Nav } from "react-bootstrap";

import './navbar.css'

import "./navbar.css"

const MyNavbar = () => (

        <Navbar fixed="top" variant="light" className="my-navbar" expand="lg">
            <Navbar.Brand href="/"><img src={ Logo } style={{width: "60px", margin:"0 20px"}} alt="logo"/></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto navbar-links">
                <Nav.Link className="navbar-link" href="/#home">Home</Nav.Link>
                <Nav.Link className="navbar-link" href="/#about">Sobre Nós</Nav.Link>
                <Nav.Link className="navbar-link" href="/#events">Projetos</Nav.Link>
                <Nav.Link className="navbar-link" href="/#members">Integrantes</Nav.Link>
                <Nav.Link className="navbar-link" href="/#footer">Contato</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
)

export default MyNavbar
